import React from "react";
import PropTypes from "prop-types";

ScoreBoard.propTypes = {
  darkMode: PropTypes.bool.isRequired,
  score: PropTypes.number.isRequired,
};

export default function ScoreBoard(props) {
  return (
    <div
      className="score-board"
      style={props.darkMode ? { color: "white" } : { color: "black" }}
    >
      <h2>Score : {props.score}</h2>
    </div>
  );
}
