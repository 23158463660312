import "./styles.css";
import React from "react";
import { useState, useEffect } from "react";
import ScoreBoard from "./components/ScoreBoard";

import Red from "../src/images/red_apple.png";
import Green from "./images/potatochip_green.png";
import Blue from "./images/milk_pack.png";
import Yellow from "./images/banana.png";
import Orange from "./images/meat.png";
import Purple from "./images/egg_white.png";
import Blank from "./images/blank.png";

const width = 8;
const colors = [Red, Blue, Yellow, Green, Purple, Orange];

export default function App() {
  const [colorsArrange, setColorsArrange] = useState([]);
  const [squareDragging, setSquareDragging] = useState(null);
  const [squareReplaced, setSquareReplaced] = useState(null);
  const [score, setScore] = useState(0);
  const [darkMode, setDarkMode] = useState(false);

  const checkForFourCol = () => {
    for (let i = 0; i <= 39; i++) {
      const col = [i, i + width, i + width * 2, i + width * 3];
      const presentColor = colorsArrange[i];
      const isBlank = colorsArrange[i] === Blank;
      if (
        col.every((index) => colorsArrange[index] === presentColor) &&
        !isBlank
      ) {
        setScore((s) => s + 4);
        col.forEach((index) => (colorsArrange[index] = Blank));
        return true;
      }
    }
  };

  const checkForFourRow = () => {
    for (let i = 0; i < 64; i++) {
      const row = [i, i + 1, i + 2, i + 3];
      const presentColor = colorsArrange[i];
      const isBlank = colorsArrange[i] === Blank;
      const notValid = [
        5, 6, 7, 13, 14, 15, 21, 22, 23, 29, 30, 31, 37, 38, 39, 45, 46, 47, 53,
        54, 55, 62, 63, 64,
      ];

      if (notValid.includes(i)) continue;
      if (
        row.every((index) => colorsArrange[index] === presentColor) &&
        !isBlank
      ) {
        setScore((s) => s + 4);
        row.forEach((index) => (colorsArrange[index] = Blank));
        return true;
      }
    }
  };

  const checkForThreeCol = () => {
    for (let i = 0; i <= 47; i++) {
      const col = [i, i + width, i + width * 2];
      const presentColor = colorsArrange[i];
      const isBlank = colorsArrange[i] === Blank;
      if (
        col.every((index) => colorsArrange[index] === presentColor) &&
        !isBlank
      ) {
        setScore((s) => s + 3);
        col.forEach((index) => (colorsArrange[index] = Blank));
        return true;
      }
    }
  };

  const checkForThreeRow = () => {
    for (let i = 0; i < 64; i++) {
      const row = [i, i + 1, i + 2];
      const presentColor = colorsArrange[i];
      const isBlank = colorsArrange[i] === Blank;
      const notValid = [
        6, 7, 14, 15, 22, 23, 30, 31, 38, 39, 46, 47, 54, 55, 63, 64,
      ];

      if (notValid.includes(i)) continue;
      if (
        row.every((index) => colorsArrange[index] === presentColor) &&
        !isBlank
      ) {
        setScore((s) => s + 3);
        row.forEach((index) => (colorsArrange[index] = Blank));
        return true;
      }
    }
  };

  const moveToBelow = () => {
    for (let i = 0; i <= 55; i++) {
      const firstRow = [0, 1, 2, 3, 4, 5, 6, 7];
      const isFirstRow = firstRow.includes(i);
      if (isFirstRow && colorsArrange[i] === Blank) {
        const randColor = Math.floor(Math.random() * colors.length);
        colorsArrange[i] = colors[randColor];
      }
      if (colorsArrange[i + width] === Blank) {
        colorsArrange[i + width] = colorsArrange[i];
        colorsArrange[i] = Blank;
      }
    }
  };

  const dragStart = (e) => {
    setSquareDragging(e.target);
  };

  const dragDrop = (e) => {
    setSquareReplaced(e.target);
  };

  const dragEnd = () => {
    const dragId = parseInt(squareDragging.getAttribute("data-id"));
    const replaceId = parseInt(squareReplaced.getAttribute("data-id"));

    colorsArrange[replaceId] = squareDragging.getAttribute("src");
    colorsArrange[dragId] = squareReplaced.getAttribute("src");

    const validMoves = [dragId + 1, dragId - width, dragId - 1, dragId + width];

    const validMove = validMoves.includes(replaceId);

    const is4col = checkForFourCol();
    const is4Row = checkForFourRow();
    const is3Col = checkForThreeCol();
    const is3Row = checkForThreeRow();

    if (replaceId && validMove && (is4col || is4Row || is3Col || is3Row)) {
      setSquareDragging(null);
      setSquareReplaced(null);
    } else {
      colorsArrange[replaceId] = squareReplaced.getAttribute("src");
      colorsArrange[dragId] = squareDragging.getAttribute("src");
      setColorsArrange([...colorsArrange]);
    }
  };

  const createboard = () => {
    const colorsArray = [];

    for (let i = 0; i < width * width; i++) {
      const randColor = Math.floor(Math.random() * colors.length);
      colorsArray.push(colors[randColor]);
    }
    setColorsArrange(colorsArray);
  };

  useEffect(() => {
    createboard();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      checkForFourCol();
      checkForFourRow();
      checkForThreeCol();
      checkForThreeRow();
      moveToBelow();
      setColorsArrange([...colorsArrange]);
    }, 150);
    return () => clearInterval(timer);
  }, [
    checkForFourCol,
    checkForFourRow,
    checkForThreeCol,
    checkForThreeRow,
    moveToBelow,
    colorsArrange,
  ]);

  function toggleDarkMode() {
    setDarkMode((e) => !e);
  }

  function toggleReset() {
    setScore(0);
    createboard();
  }
  return (
    <div
      className="App"
      style={
        darkMode ? { backgroundColor: "black" } : { backgroundColor: "white" }
      }
    >
      <div className="items">
        <div className="game-title">
          <h1 style={darkMode ? { color: "white" } : { color: "black" }}>
            Food Crush
          </h1>
        </div>
        <ScoreBoard score={score} darkMode={darkMode} />
        <button onClick={toggleDarkMode} className="darkModeBtn">
          {darkMode ? "Light Mode" : "Dark Mode"}
        </button>
        <button onClick={toggleReset} className="darkModeBtn">
          Reset
        </button>
      </div>
      <div className="game">
        {colorsArrange.map((col, index) => (
          <img
            key={index}
            src={col}
            alt={col}
            data-id={index}
            draggable={true}
            onDragStart={dragStart}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={dragDrop}
            onDragEnd={dragEnd}
          />
        ))}
      </div>
    </div>
  );
}
